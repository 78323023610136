@import "main";

html {
    body {
        font-family: sfProLight, sans-serif;
        font-weight: 300;
        color: $text-color;
        background: $its-light-gray;
        max-width: 100vw;
        overflow-x: hidden;

        .page-container {
            margin-top: 100px; //per via della navbar
        }

        div.separator {
            height: 1px;
            width: 60%;
            background-color: $its-blu-logo;
            margin: 24px auto;
        }

        nav.navbar {
            @include viewportFixedTopFullWidth;
            z-index: $zindex-popover;
            font-family: sfProThin, sans-serif;
        }

        a {
            text-decoration: none;
            color: inherit;
            &:hover {
                @include textHighlight;
            }
        }

        footer {
            background: $footer-bg-gradient;
            color: white;

            a:hover {
                color: $its-yellow;
            }

            #footer-logo {
                min-width: 120px;
                width: 20%;
            }

            #email-icon {
                font-size: 48px;
            }
        }
    }

}

